import React from "react";
import IntlDateTime from "./IntlDateTime";
import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from "./mail/SignInButton";
import SignOutButton from "./mail/SignOutButton";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import api from "services/api";

export default function HeaderRightMenu(props) {
  const isAuthenticated = useIsAuthenticated();

  const markAsRead = async (id) => {
    try {
      const response = await api.post("/notifications-read", {
        id: id,
      });
      if (response.status === 200 && response.data) {
        // After marking the notification as read, you may choose to update the notifications list
        props.setCallNotifications(true);

        // Delay for 500 milliseconds before setting the notification back to false
        setTimeout(() => {
          props.setCallNotifications(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };
  return (
    <div className="header_right_menus ms-auto">
      <IntlDateTime />

      <div className="item dropdown">
        <Link to="#" className=" dropdown-toggle" data-bs-toggle="dropdown">
          <Badge
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
            }}
            bg="danger"      
          >
            {props.unreadNotifications.length > 0
              ? props.unreadNotifications.length
              : ""}
          </Badge>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="42"
            height="42"
            viewBox="0 0 42 42"
          >
            <defs>
              <filter
                id="Rectangle_1656"
                x="0"
                y="0"
                width="42"
                height="42"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Rectangle_1656)"
            >
              <g
                id="Rectangle_1656-2"
                data-name="Rectangle 1656"
                transform="translate(9 6)"
                fill="rgba(255,74,74,0.64)"
                stroke="rgba(255,74,74,0.35)"
                stroke-linecap="round"
                stroke-width="4"
              >
                <rect width="24" height="24" rx="7" stroke="none" />
                <rect x="2" y="2" width="20" height="20" rx="5" fill="none" />
              </g>
            </g>
          </svg>
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          {props.unreadNotifications.map((item, index) => (
            <>
              <Link
                to={item.url}
                key={index}
                onClick={() => markAsRead(item.id)}
                className="dropdown-item"
              >
                <h6 className="fw-normal mb-0">{item.title}</h6>
                <p style={{ fontSize: "13px" }}>{item.description}</p>
                <small>
                  {moment(item.created_at).format("MMMM Do YYYY, h:mm A")}
                </small>
              </Link>
              <hr className="dropdown-divider" />
            </>
          ))}

          <a href="#" className="dropdown-item text-center">
            See all notifications
          </a>
        </div>
      </div>
      <div className="item">
        <a
          href="https://web.whatsapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-whatsapp"
            viewBox="0 0 16 16"
          >
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
          </svg>
        </a>
      </div>
      <div className="item">
        <Link to="#" className="#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="41.126"
            height="40.921"
            viewBox="0 0 41.126 40.921"
          >
            <defs>
              <filter
                id="Ellipse_316"
                x="0"
                y="0"
                width="36"
                height="36"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter
                id="Line_522"
                x="13.419"
                y="13.732"
                width="27.707"
                height="27.189"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-2" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_316)">
              <g
                id="Ellipse_316-2"
                data-name="Ellipse 316"
                transform="translate(9 6)"
                fill="none"
                stroke="#2b2b2b"
                stroke-width="2"
              >
                <circle cx="9" cy="9" r="9" stroke="none" />
                <circle cx="9" cy="9" r="8" fill="none" />
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Line_522)">
              <line
                id="Line_522-2"
                data-name="Line 522"
                x2="6.881"
                y2="6.363"
                transform="translate(23.83 21.15)"
                fill="none"
                stroke="#2b2b2b"
                stroke-linecap="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </Link>
      </div>

      <div className="item dropdown">
        <Link to="#" className=" dropdown-toggle" data-bs-toggle="dropdown">
          <Badge
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
            }}
            bg="danger"
          >
            {props.unreadNotifications.length > 0
              ? props.unreadNotifications.length
              : ""}
          </Badge>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="37"
            height="38"
            viewBox="0 0 37 38"
          >
            <defs>
              <filter
                id="Rectangle_1658"
                x="0"
                y="0"
                width="37"
                height="22"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter
                id="Rectangle_1659"
                x="0"
                y="8"
                width="37"
                height="22"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-2" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter
                id="Rectangle_1660"
                x="0"
                y="16"
                width="37"
                height="22"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-3" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur-3" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Rectangle_1658)"
            >
              <rect
                id="Rectangle_1658-2"
                data-name="Rectangle 1658"
                width="19"
                height="4"
                rx="1"
                transform="translate(9 6)"
                fill="#2b2b2b"
              />
            </g>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Rectangle_1659)"
            >
              <rect
                id="Rectangle_1659-2"
                data-name="Rectangle 1659"
                width="19"
                height="4"
                rx="1"
                transform="translate(9 14)"
                fill="#2b2b2b"
              />
            </g>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Rectangle_1660)"
            >
              <rect
                id="Rectangle_1660-2"
                data-name="Rectangle 1660"
                width="19"
                height="4"
                rx="1"
                transform="translate(9 22)"
                fill="#2b2b2b"
              />
            </g>
          </svg>
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          {/* <Link to="#" className="dropdown-item">
    <h6 className="fw-normal mb-0">Email Configuration</h6>
  </Link> */}
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </div>
      </div>
    </div>
  );
}
