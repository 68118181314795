import React, { useState, useEffect, useCallback } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import "react-quill/dist/quill.snow.css"; // Import styles

export default function SingleMailDetails(props) {
  //MAIL READ FUNCTION
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (props.selectedMail && !props.selectedMail.isRead) {
      const timer = setTimeout(() => {
        markAsRead();
      }, 5000); //

      return () => clearTimeout(timer);
    }
  }, [props.selectedMail]);

  const markAsRead = async () => {
    if (!props.selectedMail) return;

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const accessToken = response.accessToken;
      const selectedMailId = props.selectedMail.id;

      const updateResponse = await fetch(
        `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${selectedMailId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isRead: true,
          }),
        }
      );

      if (updateResponse.ok) {
        props.selectedMail.isRead = true;
      } else {
        console.error("Failed to mark email as read");
      }
    } catch (error) {
      console.error("Error marking email as read", error);
    }
  };

  // Attachment Functionalities
  const handleDownload = (attachment) => {
    const link = document.createElement("a");
    link.href = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
    link.download = attachment.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreview = (attachment) => {
    // Create a Blob from the base64 content
    const byteCharacters = atob(attachment.contentBytes);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: attachment.contentType });

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Open the Blob URL in a new tab
    window.open(blobUrl, "_blank");
  };

  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);

  const getDriveId = async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_MICROSOFT_API_URL}/me/drives`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    const driveId = data.value.find(
      (drive) => drive.name.includes("Documents") // Adjust this based on your drive names
    )?.id;

    return driveId;
  };

  const handleSaveToOneDrive = async (attachment) => {
    try {
      // Get the access token
      const token = await getAccessToken();
      // Convert the base64 content to binary
      const byteCharacters = atob(attachment.contentBytes);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: attachment.contentType });

      // Upload the file to the PABX folder in the correct drive
      const response = await fetch(
        `${process.env.REACT_APP_MICROSOFT_API_URL}/drives/PABX/${attachment.name}:/content`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": attachment.contentType,
          },
          body: blob,
        }
      );

      if (response.ok) {
        console.log("File uploaded to OneDrive successfully");
      } else {
        console.error(
          "Failed to upload file to OneDrive",
          await response.json()
        );
      }
    } catch (error) {
      console.error("Error saving to OneDrive", error);
    }
  };

  const handleCopy = (attachment) => {
    // Implement copy functionality
    console.log("Copy:", attachment.name);
  };

  // UserDetails

  const [user, setUser] = useState({});
  const [messages, setMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const [userModal, setUserModal] = useState(false);
  const closeUserModal = () => {
    setUserModal(false);
    setUser({});
    setMessages([]);
    setAttachments([]);
  };

  const userDetails = async (email) => {
    closeUserModal();
    if (accounts.length > 0) {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const accessToken = response.accessToken;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        // Fetch profile, presence, messages
        const [profile, messagesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_MICROSOFT_API_URL}/users/${email}`, {
            headers,
          }).then((res) => res.json()),

          fetch(
            `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages?$search="from:${email} OR to:${email}"`,
            { headers }
          ).then((res) => res.json()),
        ]);

        setUserModal(true);
        setActiveTab("overview");

        let photoUrl = null;
        // Fetch user photo
        const photoResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/users/${email}/photo/$value`,
          {
            headers,
          }
        );

        if (photoResponse.ok) {
          photoUrl = photoResponse.url;
        }
        // Filter messages with attachments
        const messagesWithAttachments = messagesResponse.value.filter(
          (message) => message.hasAttachments
        );

        // Fetch attachments for each message with attachments
        const attachments = await Promise.all(
          messagesWithAttachments.map(async (message) => {
            const messageId = message.id;
            const attachmentData = await fetch(
              `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${messageId}/attachments`,
              { headers }
            ).then((res) => res.json());

            // Return structured data containing message subject and attachments
            return {
              subject: message.subject,
              attachments: attachmentData.value,
            };
          })
        );

        // Set user details including photo URL
        setUser({ ...profile, photoUrl: photoUrl }); // Store the photo URL along with other user details
        setMessages(messagesResponse.value); // Corrected to use messagesResponse.value
        setAttachments(attachments); // Corrected spelling and assigned the attachments
      } catch (error) {
        console.error("Error acquiring token or fetching user details", error);
      }
    }
  };

  const [activeTab, setActiveTab] = useState("overview");

  return (
    <>
      {props.selectedMail.id && (
        <>
          <div className="mail_body_area">
            <div className="mail_header">
              <div className="users_area">
                <div className="thumb">
                  <img
                    onClick={() =>
                      userDetails(
                        props.selectedMail.from?.emailAddress?.address
                      )
                    }
                    style={{ cursor: "pointer" }}
                    width={22}
                    src={
                      props.selectedMail.senderImage ||
                      require("../../assets/images/logos/notification.png")
                        .default
                    }
                    alt="Logo"
                  />
                </div>

                <div className="user_details">
                  <div
                    className="username"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      userDetails(
                        props.selectedMail.from?.emailAddress?.address
                      )
                    }
                  >
                    {props.selectedMail.from?.emailAddress?.name ||
                      props.selectedMail.from?.emailAddress?.address}
                  </div>
                  <div className="toString">
                    <strong> To: </strong>
                    {props.selectedMail.toRecipients?.map(
                      (recipient, index) => (
                        <React.Fragment key={recipient.emailAddress?.address}>
                          <button
                            className="mail_recepients_btn"
                            onClick={() =>
                              userDetails(recipient.emailAddress?.address)
                            }
                          >
                            {recipient.emailAddress?.name ||
                              recipient.emailAddress?.address}
                          </button>
                          {index < props.selectedMail.toRecipients.length - 1 &&
                            "; "}
                        </React.Fragment>
                      )
                    )}
                  </div>

                  <div className="toString">
                    <strong>CC: </strong>
                    {props.selectedMail.ccRecipients?.map(
                      (recipient, index) => (
                        <React.Fragment key={index}>
                          <button
                            className="mail_recepients_btn"
                            onClick={() =>
                              userDetails(recipient.emailAddress?.address)
                            }
                          >
                            {recipient.emailAddress?.name ||
                              recipient.emailAddress?.address}
                          </button>
                          {index < props.selectedMail.ccRecipients.length - 1 &&
                            "; "}
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              </div>
              {props.replyble ? (
                <div className="extranal_btns">
                  <div className="button_grups">
                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("Reply");
                      }}
                    >
                      Reply
                    </button>

                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("ReplyAll");
                      }}
                    >
                      Reply All
                    </button>
                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("Forward");
                      }}
                    >
                      Forward
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ fontSize: "12px" }}>
                  {moment(props.receivedDateTime).format(
                    "MMM D, YYYY [at] h:mm A"
                  )}
                </div>
              )}
            </div>
            <hr />
            {props.selectedMail?.attachments &&
              props.selectedMail.attachments.length > 0 && (
                <div className="attachments">
                  {props.selectedMail.attachments
                    .filter((attachment) => !attachment.isInline)
                    .map((attachment, index) => (
                      <div key={index} className="single_attachment">
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => handlePreview(attachment)}
                        >
                          {attachment.name.endsWith("png") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-image"
                            ></i>
                          ) : attachment.name.endsWith("jpg") ||
                            attachment.name.endsWith("jpeg") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-image"
                            ></i>
                          ) : attachment.name.endsWith("pdf") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-pdf"
                            ></i>
                          ) : attachment.name.endsWith("doc") ||
                            attachment.name.endsWith("docx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-word"
                            ></i>
                          ) : attachment.name.endsWith("xls") ||
                            attachment.name.endsWith("xlsx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-excel"
                            ></i>
                          ) : attachment.name.endsWith("ppt") ||
                            attachment.name.endsWith("pptx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-powerpoint"
                            ></i>
                          ) : (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file"
                            ></i>
                          )}
                          {attachment.name}
                        </button>
                        <DropdownButton
                          id={`dropdown-${index}`}
                          title={<i className="fa fa-angle-down" />}
                          variant="secondary"
                          className="dropdown_toggle"
                        >
                          <Dropdown.Item
                            onClick={() => handlePreview(attachment)}
                          >
                            Preview
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleSaveToOneDrive(attachment)}
                          >
                            Save to OneDrive
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCopy(attachment)}>
                            Copy
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDownload(attachment)}
                          >
                            Download
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    ))}
                </div>
              )}

            <div
              style={{ width: "100%", overflowY: "hidden" }}
              className="email_textarea_body"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedMail.body?.content,
                }}
              />
            </div>
          </div>
          <Modal size="lg" show={userModal} onHide={closeUserModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  style={{
                    height: "70px",
                    width: "70px",
                    borderRadius: "50%",
                    marginRight: "15px",
                  }}
                  src={
                    user.photoUrl ||
                    require("../../assets/images/logos/notification.png")
                      .default
                  }
                />
                {user.displayName ? user.displayName : user.userPrincipalName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-tabs">
                <button
                  className={`tab ${activeTab === "overview" ? "active" : ""}`}
                  onClick={() => setActiveTab("overview")}
                >
                  Overview
                </button>
                <button
                  className={`tab ${activeTab === "files" ? "active" : ""}`}
                  onClick={() => setActiveTab("files")}
                >
                  Files
                </button>
                <button
                  className={`tab ${activeTab === "messages" ? "active" : ""}`}
                  onClick={() => setActiveTab("messages")}
                >
                  Messages
                </button>
              </div>

              {activeTab === "overview" && (
                <div className="overview-content">
                  <br />
                  <div className="availability">
                    <p>Available • Free on (Not Set)</p>
                    <p>Work hours: (Not Set)</p>
                  </div>
                  <h6>Contact Information</h6>
                  <div className="availability">
                    <a href={`mailto:{user.userPrincipalName}`}>
                      <i class="fa fa-envelope"></i> {user.userPrincipalName}
                    </a>
                  </div>
                </div>
              )}

              {activeTab === "files" && (
                <div
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                  }}
                  className="linkedin-info"
                >
                  <br />
                  <div className="list-group list-group-flush">
                    {attachments &&
                      attachments
                        .filter((item) => item.attachments)
                        .map((item, index) => (
                          <div key={index} className="list-group-item">
                            {item.attachments
                              .filter((attachment) => !attachment.isInline)
                              .map((attachment, attachmentIndex) => (
                                <a
                                  className="text-muted"
                                  style={{
                                    textDecoration: "none",
                                    color: "#010101",
                                    paddingRight: "20px",
                                  }}
                                  key={attachmentIndex}
                                  href={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
                                  download={attachment.name}
                                >
                                  {(() => {
                                    const iconStyle = {
                                      fontSize: "30px",
                                      marginRight: "5px",
                                    };
                                    if (attachment.name.endsWith("png")) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-image"
                                        ></i>
                                      );
                                    } else if (
                                      ["jpg", "jpeg"].some((ext) =>
                                        attachment.name.endsWith(ext)
                                      )
                                    ) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file-image"
                                        ></i>
                                      );
                                    } else if (
                                      attachment.name.endsWith("pdf")
                                    ) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file-pdf"
                                        ></i>
                                      );
                                    } else if (
                                      ["doc", "docx"].some((ext) =>
                                        attachment.name.endsWith(ext)
                                      )
                                    ) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file-word"
                                        ></i>
                                      );
                                    } else if (
                                      ["xls", "xlsx"].some((ext) =>
                                        attachment.name.endsWith(ext)
                                      )
                                    ) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file-excel"
                                        ></i>
                                      );
                                    } else if (
                                      ["ppt", "pptx"].some((ext) =>
                                        attachment.name.endsWith(ext)
                                      )
                                    ) {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file-powerpoint"
                                        ></i>
                                      );
                                    } else {
                                      return (
                                        <i
                                          style={iconStyle}
                                          className="fa fa-file"
                                        ></i>
                                      );
                                    }
                                  })()}
                                  <span style={{ fontSize: "20px" }}>
                                    {" "}
                                    {attachment.name}
                                  </span>
                                </a>
                              ))}
                          </div>
                        ))}
                  </div>
                </div>
              )}
              {activeTab === "messages" && (
                <div
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                  }}
                  className="messages"
                >
                  {Array.isArray(messages) && messages.length > 0 ? (
                    messages.map((item, index) => (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          props.setMailID(item.id);
                          setUserModal(false);
                        }}
                        key={index}
                        className="card p-2"
                      >
                        <div>{item.sender?.emailAddress?.name}</div>
                        <h6>{item.subject ? item.subject : "No Subject"}</h6>
                        <div
                          style={{ fontSize: "11px" }}
                          className="text-muted"
                        >
                          {item.receivedDateTime
                            ? moment(item.receivedDateTime).format("lll")
                            : "Date not available"}
                        </div>
                      </div>
                    ))
                  ) : (
                    <li>No emails found or failed to load emails</li>
                  )}
                </div>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
