import React, { useState, useRef } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const Toolbar = ({
  onUndo,
  onRedo,
  handleFileSelect,
  toggleImportance,
  importance,
}) => {
  const handleButtonClick = (command, value = null) => {
    const quill = window.quill; // Get the Quill instance
    if (quill) {
      quill.focus(); // Focus on the editor

      if (command === "link") {
        const url = prompt("Enter the link URL");
        if (url) {
          quill.format("link", url); // Apply the link format
        }
      } else if (value) {
        quill.format(command, value); // Apply the format with a value (e.g., for alignment)
      } else {
        quill.format(command, true); // Apply the format without a value (e.g., bold, italic)
      }
    }
  };

  const [showColorPalette, setShowColorPalette] = useState(false); // Controls the visibility of color palette
  const [selectedColor, setSelectedColor] = useState("#000000");

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const quill = window.quill;
    if (quill) {
      quill.format("color", color); // Apply selected color to text
    }
    setShowColorPalette(false); // Hide the palette after color is selected
  };

  const colorOptions = [
    "#000000",
    "#FFFFFF",
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FFA500",
    "#800080",
    "#008080",
    "#FFC0CB",
    "#A52A2A",
    "#808080",
    "#FFD700",
    "#00FFFF",
    "#FF00FF",
    "#808000",
    "#000080",
    "#800000",
    "#40E0D0",
    "#D2691E",
    "#ef9a3e",
    "#A7A7A7",
    "#cfcfcf",
  ];
  // ef9a3e

  const handleSizeChange = (event) => {
    const size = event.target.value;
    const quill = window.quill;
    if (quill) {
      quill.format("size", size);
    }
  };

  //image upload on editor

  const fileInputRef = useRef(null); // Reference to the file input element

  // Function to handle file selection
  const imageSelect = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result; // Get the base64 string from the file
        const quill = window.quill; // Access the Quill instance
        if (quill) {
          // Ensure the editor is focused
          quill.focus();

          // Get the current selection range
          let range = quill.getSelection();

          // If no selection, set the range to the end of the document
          if (!range) {
            range = {
              index: quill.getLength(), // Insert image at the end of the document
              length: 0,
            };
          }

          // Insert the image at the cursor (or at the end of the content)
          quill.insertEmbed(range.index, "image", base64Image);
        }
      };
      reader.readAsDataURL(file); // Read the file as a base64 data URL
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input when the image button is clicked
  };

  return (
    <>
      <div
        className="toolbar"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <button title="Undo" onClick={onUndo}>
            <i className="fa fa-undo"></i>
          </button>
          <button title="Redo" onClick={onRedo}>
            <i className="fa fa-redo"></i>
          </button>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <select
            style={{
              border: "1px solid rgb(231 217 217)",
              color: "#555",
              borderRadius: "4px",
            }}
            onChange={handleSizeChange}
          >
            <option value="">Normal</option>
            <option value="small">Small</option>
            <option value="large">Large</option>
            <option value="huge">Huge</option>
          </select>
          <button title="Bold" onClick={() => handleButtonClick("bold")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
            >
              <text
                id="B"
                transform="translate(0 11)"
                fontSize="12"
                fontFamily="Arial-BoldMT, Arial"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  B
                </tspan>
              </text>
            </svg>
          </button>
          <button title="Italic" onClick={() => handleButtonClick("italic")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="14"
              viewBox="0 0 4 14"
            >
              <text
                id="I"
                transform="translate(0 11)"
                fontSize="12"
                fontFamily="Arial-ItalicMT, Arial"
                fontStyle="italic"
              >
                <tspan x="0" y="0">
                  I
                </tspan>
              </text>
            </svg>
          </button>
          <button
            title="Underline"
            onClick={() => handleButtonClick("underline")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
            >
              <text
                id="U"
                transform="translate(0 11)"
                fontSize="12"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  U
                </tspan>
              </text>
            </svg>
          </button>
          <button title="Strike" onClick={() => handleButtonClick("strike")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.5"
              height="14"
              viewBox="0 0 11.5 14"
            >
              <text
                id="S"
                transform="translate(1.75 11)"
                fontSize="12"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  S
                </tspan>
              </text>
              <line
                id="Line_493"
                data-name="Line 493"
                x2="11"
                transform="translate(0.25 7)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.5"
              />
            </svg>
          </button>
          <button title="Link" onClick={() => handleButtonClick("link")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
            >
              <text
                id="H"
                transform="translate(0 11)"
                fontSize="12"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  H
                </tspan>
              </text>
            </svg>
          </button>
          <button
            title="Bullet"
            onClick={() => handleButtonClick("list", "bullet")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="16"
              viewBox="0 0 6 16"
            >
              <text
                id="_-_-_-"
                data-name="-  
-
-"
                transform="translate(0 5)"
                fontSize="6"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0" space="preserve">
                  -{" "}
                </tspan>
                <tspan x="0" y="5">
                  -
                </tspan>
                <tspan x="0" y="10">
                  -
                </tspan>
              </text>
            </svg>
          </button>
          <button
            title="Numbering"
            onClick={() => handleButtonClick("list", "ordered")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="14"
              viewBox="0 0 4 14"
            >
              <text
                id="_1_2_3_"
                data-name="1  
2
3 "
                transform="translate(0 3)"
                fontSize="3"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0" space="preserve">
                  1{" "}
                </tspan>
                <tspan x="0" y="5">
                  2
                </tspan>
                <tspan x="0" y="10">
                  3{" "}
                </tspan>
              </text>
            </svg>
          </button>

          <button
            title="Left"
            onClick={() => handleButtonClick("align", "justify")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.701"
              height="8.416"
              viewBox="0 0 11.701 8.416"
            >
              <line
                id="Line_518"
                data-name="Line 518"
                y1="0.015"
                x2="7"
                transform="translate(0.351 0.351)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_519"
                data-name="Line 519"
                x2="11"
                transform="translate(0.351 4.219)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_520"
                data-name="Line 520"
                x2="7"
                transform="translate(0.351 8.066)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
            </svg>
          </button>

          <button
            title="Center"
            onClick={() => handleButtonClick("align", "center")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.7"
              height="8.561"
              viewBox="0 0 11.7 8.561"
            >
              <line
                id="Line_518"
                data-name="Line 518"
                x2="5.5"
                transform="translate(2.85 0.35)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_519"
                data-name="Line 519"
                x2="11"
                transform="translate(0.35 4.218)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_520"
                data-name="Line 520"
                x2="5.5"
                transform="translate(3.35 8.211)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
            </svg>
          </button>

          <button
            title="Right"
            onClick={() => handleButtonClick("align", "right")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.35"
              height="11.659"
              viewBox="0 0 13.35 11.659"
            >
              <line
                id="Line_496"
                data-name="Line 496"
                x2="11"
                transform="translate(2 0.35)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_497"
                data-name="Line 497"
                x2="7"
                transform="translate(6 3.09)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_498"
                data-name="Line 498"
                x2="7"
                transform="translate(6 5.83)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_501"
                data-name="Line 501"
                x2="7"
                transform="translate(6 8.569)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <line
                id="Line_502"
                data-name="Line 502"
                x2="11"
                transform="translate(2 11.309)"
                fill="none"
                stroke="#0d0d0d"
                strokeLinecap="round"
                strokeWidth="0.7"
              />
              <path
                id="Polygon_177"
                data-name="Polygon 177"
                d="M1.652,1.357a1,1,0,0,1,1.7,0l.7,1.113A1,1,0,0,1,3.2,4H1.8A1,1,0,0,1,.956,2.47Z"
                transform="translate(0 7.835) rotate(-90)"
                fill="#707070"
              />
            </svg>
          </button>

          <input
            type="file"
            multiple
            style={{ display: "none" }}
            id="attachmentInput"
            onChange={handleFileSelect} // Call handleFileSelect when files are selected
          />
          <label
            title="Attatchments"
            htmlFor="attachmentInput"
            className="file-upload-label label_button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.435"
              height="13.435"
              viewBox="0 0 13.435 13.435"
            >
              <g
                id="Rectangle_1570"
                data-name="Rectangle 1570"
                transform="translate(0 9.899) rotate(-45)"
                fill="none"
                stroke="#707070"
                strokeWidth="1"
              >
                <rect width="14" height="5" rx="2.5" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="13"
                  height="4"
                  rx="2"
                  fill="none"
                />
              </g>
            </svg>
          </label>

          <button
            className={importance === "high" ? "active" : ""}
            onClick={toggleImportance}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="16"
              viewBox="0 0 5 16"
            >
              <text
                id="_"
                data-name="!"
                transform="translate(0 13)"
                fill="rgba(255,74,74,0.6)"
                fontSize="14"
                fontFamily="Arial-BoldMT, Arial"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  !
                </tspan>
              </text>
            </svg>
          </button>

          <button title="Insert Image" onClick={handleImageClick}>
            <i className="far fa-image"></i>
          </button>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef} // Reference to trigger file selection
            onChange={imageSelect} // Handle the file when selected
          />
        </div>

        <div style={{ display: "flex", gap: "10px", position: "relative" }}>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
            >
              <path
                id="Polygon_66"
                data-name="Polygon 66"
                d="M2.681,1.17a1,1,0,0,1,1.638,0L5.9,3.427A1,1,0,0,1,5.079,5H1.921A1,1,0,0,1,1.1,3.427Z"
                transform="translate(7)"
                fill="#707070"
              />
              <text
                id="A"
                transform="translate(1 12)"
                fontSize="12"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  A
                </tspan>
              </text>
            </svg>
          </button>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
            >
              <path
                id="Polygon_67"
                data-name="Polygon 67"
                d="M2.681,1.17a1,1,0,0,1,1.638,0L5.9,3.427A1,1,0,0,1,5.079,5H1.921A1,1,0,0,1,1.1,3.427Z"
                transform="translate(12 5) rotate(180)"
                fill="#707070"
              />
              <text
                id="A"
                transform="translate(1 11)"
                fontSize="10"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  A
                </tspan>
              </text>
            </svg>
          </button>

          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="13"
              viewBox="0 0 15 13"
            >
              <text
                id="_"
                data-name="…"
                transform="translate(3) rotate(90)"
                fontSize="13"
                fontFamily="ArialMT, Arial"
              >
                <tspan x="0" y="0">
                  …
                </tspan>
              </text>
            </svg>
          </button>

          {/* <div title="Color" style={{ position: "relative" }}>
            <button onClick={() => setShowColorPalette(!showColorPalette)}>
              <i className="fa fa-palette" style={{ color: selectedColor }}></i>
            </button>

            {showColorPalette && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  zIndex: 10,
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  padding: "5px",
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 20px)",
                  gap: "5px",
                }}
              >
                {colorOptions.map((color) => (
                  <div
                    key={color}
                    onClick={() => handleColorSelect(color)}
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: color,
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid green",
                    }}
                  ></div>
                ))}
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Toolbar;
