import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import { mailMinimize } from "../../minimize/mailMinimize";
import InboxHeader from "./InboxHeader";
import MailingSingle from "./MailingSingle";
import MailContextMenu from "./MailContextMenu";

export default function MailList(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [loadingMore, setLoadingMore] = useState(false);
  const [markAble, setMarkAble] = useState(false); // Define markAble state
  const [filterAble, setFilterAble] = useState(false); // Define filterAble state
  const [page, setPage] = useState(1);
  const inboxRef = useRef(null);
  const [filter, setFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("");
  const [nextLink, setNextLink] = useState(null); // Tracks the next link for pagination
  const [hasMore, setHasMore] = useState(false); // Whether there are more emails to fetch
  const [requestCount, setRequestCount] = useState(0); // Tracks the number of requests made in the current batch
  const [showNextButton, setShowNextButton] = useState(false); // Controls the visibility of the "Next" button
  const [fetchedCount, setFetchedCount] = useState(0); // New state to track fetched emails
  //toMe cc to filters on diffrent way
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [toMeActive, setToMeActive] = useState(false);
  const [toEmailsActive, setToEmailsActive] = useState(false);
  const [ccEmailsActive, setCcEmailsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  //mail chain
  const [expandedChains, setExpandedChains] = useState({});
  //NEW ERA
  // Memoize access token to avoid acquiring it unnecessarily
  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);
  mailMinimize.setAccessTokenAndEmails(getAccessToken, props.setEmails);
  const getEmails = useCallback(
    async (skipCount = 0) => {
      if (accounts.length > 0 && props.mailFolder?.folderId) {
        try {
          const accessToken = await getAccessToken();
          // Prepare the base URL for fetching emails
          let baseURL = `https://graph.microsoft.com/v1.0/me/mailFolders/${props.mailFolder.folderId}/messages?$top=20`;
          // Use skipCount to skip previously fetched emails
          let nextLinkURL = `${baseURL}&$skip=${skipCount}`;

          // Destructure mailSearchData
          const {
            fromEmails,
            toEmails,
            ccEmails,
            subject,
            keywords,
            dateFrom,
            dateTo,
            isRead,
            attachments,
          } = props.mailSearchData;

          let filterQuery = "";
          let sortQuery = "";

          // Set sorting based on the sortOrder
          switch (sortOrder) {
            case "date":
              sortQuery = `$orderby=receivedDateTime asc`;
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            case "from":
              sortQuery = `$orderby=from/emailAddress/name asc`;
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            case "subject":
              sortQuery = `$orderby=subject asc`;
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            default:
              sortQuery = ``;
          }

          const filters = [];

          // Handle filters
          if (isRead !== undefined) {
            filters.push(`isRead eq ${isRead}`);
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }
          const myMail = accounts[0].username.toLowerCase();
          // General filters
          switch (filter) {
            case "unread":
              filters.push(`isRead eq false`);
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            case "toMe":
              filters.push(
                `toRecipients/any(r:r/emailAddress/address) eq '${myMail}'`
              );
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            case "flagged":
              filters.push(`flag/flagStatus eq 'flagged'`);
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            case "mentionsMe":
              filters.push(`contains(body/content, '${myMail}')`);
              setCcEmailsActive(false);
              setToEmailsActive(false);
              setToMeActive(false);
              break;
            default:
              break;
          }

          // Adding various filters to the query
          if (fromEmails?.length > 0) {
            const fromFilter = fromEmails
              .map((email) => `from/emailAddress/address eq '${email}'`)
              .join(" or ");
            filters.push(`(${fromFilter})`);
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }

          if (toEmails?.length > 0) {
            setToEmailsActive(true);
          }

          if (ccEmails?.length > 0) {
            setCcEmailsActive(true);
          }

          if (subject) {
            filters.push(`contains(subject, '${subject}')`);
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }

          if (keywords) {
            filters.push(`contains(body/content, '${keywords}')`);
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }

          if (dateFrom && dateTo) {
            filters.push(
              `(receivedDateTime ge ${new Date(
                dateFrom
              ).toISOString()} and receivedDateTime le ${new Date(
                dateTo
              ).toISOString()})`
            );
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }

          if (attachments) {
            filters.push(`hasAttachments eq true`);
            setCcEmailsActive(false);
            setToEmailsActive(false);
            setToMeActive(false);
          }

          // Combine all filters
          if (filters.length > 0) {
            const filterString = filters.join(" and ");
            filterQuery = `&$filter=${encodeURIComponent(filterString)}`;
          }

          // Construct the final nextLinkURL
          nextLinkURL += filterQuery;
          if (sortQuery) nextLinkURL += `&${sortQuery}`;

          let requestBatchCount = 0;
          let newNextLink = nextLinkURL; // Start with the current nextLink

          // Fetch emails
          while (newNextLink && requestBatchCount < 5) {
            const emailResponse = await fetch(newNextLink, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            });

            if (emailResponse.ok) {
              const data = await emailResponse.json();

              // Process emails and track conversation threads
              const processedEmails = await Promise.all(
                data.value.map(async (email) => {
                  const subject = email.subject || "No Subject";
                  const [parsedSubject, workOrder, poNumber] =
                    subject.split("=>") || [];

                  const conversationCount = await fetchEmailsByConversationId(
                    accessToken,
                    email.conversationId
                  );

                  return {
                    ...email,
                    subject: parsedSubject || "No Subject",
                    workOrder: workOrder || "NA",
                    poNumber: poNumber || "NA",
                    hasMailChain: conversationCount,
                  };
                })
              );

              // Append new emails to the existing emails
              props.setEmails((prevEmails) => [
                ...prevEmails,
                ...processedEmails,
              ]);

              // Update the fetched count
              setFetchedCount(
                (prevCount) => prevCount + processedEmails.length
              );

              // Update the next link for pagination
              newNextLink = data["@odata.nextLink"] || null;

              requestBatchCount++;
            } else {
              console.error(
                "Failed to fetch emails:",
                emailResponse.statusText
              );
              break; // Stop further fetching if there's an error
            }
          }

          // Save the final `nextLink` after completing requests
          setNextLink(newNextLink);
          setHasMore(!!newNextLink);

          // Show the "Next" button if there are more emails to fetch
          if (newNextLink) {
            setShowNextButton(true);
          }
        } catch (error) {
          console.error("Error acquiring token or fetching emails", error);
        }
      }
    },
    [
      accounts,
      getAccessToken,
      props.mailFolder?.folderId,
      props.mailSearchData,
      sortOrder,
      filter,
    ]
  );

  const fetchEmailsByConversationId = async (
    accessToken,
    conversationId,
    retries = 3
  ) => {
    // Safely encode the conversationId
    const encodedConversationId = encodeURIComponent(conversationId);
    const url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages?$filter=conversationId eq '${encodedConversationId}'`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data.value.length; // Return the number of emails in the conversation
      } else {
        console.error(
          `Error fetching emails for conversationId: ${conversationId}. Status: ${response.status} ${response.statusText}`
        );

        // If the response is not OK and there are retries left, retry the request
        if (retries > 0) {
          console.log(`Retrying... Attempts left: ${retries}`);
          return fetchEmailsByConversationId(
            accessToken,
            conversationId,
            retries - 1
          );
        }

        return 0; // Return 0 if all retries have been exhausted
      }
    } catch (error) {
      console.error("Error in fetchEmailsByConversationId:", error);

      // If an error occurs and there are retries left, retry the request
      if (retries > 0) {
        console.log(`Retrying... Attempts left: ${retries}`);
        return fetchEmailsByConversationId(
          accessToken,
          conversationId,
          retries - 1
        );
      }

      return 0; // Return 0 if all retries have been exhausted
    }
  };

  const handleToggleMailChain = async (email) => {
    const emailId = email.id;
    if (expandedChains[emailId]) {
      setExpandedChains((prev) => ({
        ...prev,
        [emailId]: false, // Collapse the chain
      }));
    } else {
      // Fetch mail chain with same subject between logged-in user and the sender
      const accessToken = await getAccessToken();
      const chainResponse = await fetch(
        `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages?$filter=conversationId eq '${email.conversationId}'`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (chainResponse.ok) {
        const chainData = await chainResponse.json();
        // Process and map the fetched emails
        const processedMailChain = chainData.value.map((email) => {
          const subject = email.subject || "No Subject";
          const [parsedSubject, workOrder, poNumber] =
            subject.split("=>") || [];

          return {
            ...email,
            subject: parsedSubject || "No Subject",
            workOrder: workOrder || "NA",
            poNumber: poNumber || "NA",
          };
        });

        // Expand the chain with the processed emails
        setExpandedChains((prev) => ({
          ...prev,
          [emailId]: processedMailChain, // Expand the chain with processed emails
        }));
      }
    }
  };

  const handleNextBatch = () => {
    setShowNextButton(false); // Hide the button before fetching the next batch
    getEmails(fetchedCount); // Pass the number of already fetched emails
  };
  // Initial load and reset emails when folder changes
  useEffect(() => {
    if (isAuthenticated && props.mailFolder?.folderId) {
      setCcEmailsActive(false);
      setToEmailsActive(false);
      setToMeActive(false);
      setActiveIndex(null);
      props.setIsComposing(false);
      props.setSelectedMailIds([]);
      props.setSelectedMail({});
      props.setMailID(0);
      props.setEmails([]); // Clear existing emails
      setPage(1);
      setRequestCount(0); // Reset request count
      setHasMore(true); // Allow fetching more emails
      setNextLink(null); // Reset the nextLink
      getEmails(); // Fetch the first batch of emails
      setMarkAble(false);
      setFilter("all");
    }
  }, [isAuthenticated, props.mailFolder?.folderId, getEmails]);
  // Trigger fetching emails when loading more
  useEffect(() => {
    if (loadingMore) {
      getEmails();
      setLoadingMore(false); // Reset loadingMore after fetching
    }
  }, [loadingMore, getEmails]);
  // Memoized email IDs to avoid recalculating during rendering
  const allEmailIds = useMemo(
    () => props.emails.map((email) => email.id),
    [props.emails]
  );
  const toggleMarkAble = useCallback(() => {
    props.setSelectedMailIds([]);
    setMarkAble((prevMarkAble) => !prevMarkAble);
  }, [props]);
  const toggleSelectAll = useCallback(() => {
    if (props.selectedMailIds.length === props.emails.length) {
      props.setSelectedMailIds([]); // Unselect all
    } else {
      props.setSelectedMailIds(allEmailIds); // Select all
    }
  }, [props.emails.length, props, allEmailIds]);
  const toggleSelectChange = useCallback(
    (id) => {
      if (props.selectedMailIds.includes(id)) {
        props.setSelectedMailIds(
          props.selectedMailIds.filter((selectedId) => selectedId !== id)
        ); // Unselect item
      } else {
        props.setSelectedMailIds([...props.selectedMailIds, id]); // Select item
      }
    },
    [props]
  );
  const filterToggle = () => {
    setFilterAble(!filterAble);
  };
  // Filter for 'toMe'
  const toMeFilter = () => {
    if (accounts.length > 0) {
      const toMeEmail = accounts[0].username.toLowerCase();
      const filtered = props.emails.filter((email) => {
        return email.toRecipients.some(
          (recipient) =>
            recipient.emailAddress.address.toLowerCase() === toMeEmail
        );
      });
      setFilteredEmails(filtered);
    }
  };
  // Filter for 'toEmails' (array)
  const toEmailsFilter = () => {
    if (props.mailSearchData?.toEmails?.length > 0) {
      const toEmailsLowercase = props.mailSearchData?.toEmails.map((email) =>
        email.toLowerCase()
      );
      const filtered = props.emails.filter((email) => {
        return email.toRecipients.some((recipient) =>
          toEmailsLowercase.includes(
            recipient.emailAddress.address.toLowerCase()
          )
        );
      });
      setFilteredEmails(filtered);
    }
  };
  // Filter for 'ccEmails' (array)
  const ccEmailsFilter = () => {
    if (props.mailSearchData?.ccEmails?.length > 0) {
      const ccEmailsLowercase = props.mailSearchData?.ccEmails.map((email) =>
        email.toLowerCase()
      );
      const filtered = props.emails.filter((email) => {
        return email.ccRecipients.some((recipient) =>
          ccEmailsLowercase.includes(
            recipient.emailAddress.address.toLowerCase()
          )
        );
      });
      setFilteredEmails(filtered);
    }
  };
  // Use effects to trigger the filters based on active states
  useEffect(() => {
    if (toMeActive) {
      toMeFilter();
    }
  }, [props.emails, accounts, toMeActive]);

  useEffect(() => {
    if (toEmailsActive) {
      toEmailsFilter();
    }
  }, [props.emails, props.toEmails, toEmailsActive]);

  useEffect(() => {
    if (ccEmailsActive) {
      ccEmailsFilter();
    }
  }, [props.emails, props.ccEmails, ccEmailsActive]);
  //Added key UP/DOWN Functionlity
  const itemRefs = useRef([]);
  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    const currentEmails =
      props.toMeActive || props.toEmailsActive || props.ccEmailsActive
        ? filteredEmails
        : props.emails;
    const totalItems = currentEmails.length;

    if (e.key === "ArrowDown") {
      setActiveIndex((prevIndex) =>
        prevIndex + 1 < totalItems ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };
  // Ensure the selected item is always visible when navigating with keyboard
  useEffect(() => {
    const currentEmails =
      props.toMeActive || props.toEmailsActive || props.ccEmailsActive
        ? filteredEmails
        : props.emails;

    if (activeIndex !== null && currentEmails[activeIndex]) {
      const activeMail = currentEmails[activeIndex];
      props.setMailID(activeMail.id);
      // Ensure the selected item is visible by centering it in the view
      if (itemRefs.current[activeIndex]) {
        itemRefs.current[activeIndex].scrollIntoView({
          behavior: "smooth", // Smooth scrolling effect
          block: "center", // Scrolls to center the active item in view
          inline: "nearest", // For horizontal scrolling (if any)
        });
      }
    }
  }, [activeIndex]);
  // Handle clicking on a list item
  const handleItemClick = (index, itemId) => {
    setActiveIndex(index); // Update active index when clicked
    props.setMailID(itemId); // Set selected mail ID
  };

  //CONTEXT MENU
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    mail: null,
  });

  const contextMenuRef = useRef(null); // Ref for the context menu

  const handleContextMenu = (event, mail) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      mail,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, mail: null });
  };

  const handleAction = (action) => {
    switch (action) {
      case "Copy":
      case "Print":
      case "Reply":
      case "Reply All":
      case "Forward":

      case "Mark as Unread":
      case "Mark as Read":
      case "Move to":
      case "Delete":
      default:
        return null;
    }

    handleCloseContextMenu();
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseContextMenu);
    return () => document.removeEventListener("click", handleCloseContextMenu);
  }, []);

  // console.log("EXPANDED",expandedChains);

  return (
    <div
      className={`inbox non_printing_area ${
        props.extendDetailsToggle ? "collapsed" : ""
      }`}
    >
      <InboxHeader
        markAble={markAble}
        toggleSelectAll={toggleSelectAll}
        setFilter={setFilter}
        setToMeActive={setToMeActive}
        toMeFilter={toMeFilter}
        toMeActive={toMeActive}
        filter={filter}
        filterToggle={filterToggle}
        filterAble={filterAble}
        setSortOrder={setSortOrder}
        mailFolder={props.mailFolder}
        emails={props.emails}
        selectedMailIds={props.selectedMailIds}
        toggleMarkAble={toggleMarkAble}
      />

      <ul
        className="list-group"
        ref={inboxRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
      >
        {(toMeActive || toEmailsActive || ccEmailsActive
          ? filteredEmails
          : props.emails
        ).map((item, index) => (
          <div
            className="with_chain"
            key={item.id}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <MailingSingle
              item={item}
              index={index}
              activeIndex={activeIndex}
              handleItemClick={handleItemClick}
              handleContextMenu={
                props.mailID === item.id ? handleContextMenu : undefined
              }
              toggleSelectChange={toggleSelectChange}
              markAble={markAble}
              selectedMailIds={props.selectedMailIds}
              mailMinimize={mailMinimize}
              mailFolder={props.mailFolder}
             
              setMailSendType={props.setMailSendType}
              setMailID={props.setMailID}
              props={props}
              //hrhhr
              onToggleMailChain={() => handleToggleMailChain(item)}
              hasMailChain={!!item.mailChain}
            />
            {expandedChains[item.id] &&
              expandedChains[item.id].map((chainMail) => (
                <div key={chainMail.id} className="mail-chain-item">
                  <MailingSingle
                    isSubMail={true}
                    item={chainMail}
                    index={chainMail}
                    activeIndex={activeIndex}
                    handleItemClick={handleItemClick}
                    handleContextMenu={
                      props.mailID === chainMail.id
                        ? handleContextMenu
                        : undefined
                    }
                    toggleSelectChange={toggleSelectChange}
                    markAble={markAble}
                    selectedMailIds={props.selectedMailIds}
                    mailMinimize={mailMinimize}
                    mailFolder={props.mailFolder}
                    setMailSendType={props.setMailSendType}
                    setMailID={props.setMailID}
                    props={props}
                  />
                </div>
              ))}
          </div>
        ))}
        <br />
        {showNextButton && hasMore && (
          <button className="load_more_mail_btn" onClick={handleNextBatch}>
            Load More
          </button>
        )}
      </ul>
      {contextMenu.visible && (
        <MailContextMenu
          mail={contextMenu.mail}
          ref={contextMenuRef} // This ref isn't necessary in the parent anymore, handled inside the component
          x={contextMenu.x}
          y={contextMenu.y}
          mailMinimize={mailMinimize}
          props={props}
          visible={contextMenu.visible}
          onClose={handleCloseContextMenu}
          onAction={handleAction}
        />
      )}
    </div>
  );
}
