import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import { mailMinimize } from "../../minimize/mailMinimize";

export default function MailDirection(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  // Memoize access token to avoid acquiring it unnecessarily
  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);

  mailMinimize.setAccessTokenAndEmails(getAccessToken, props.setEmails);

  const CancelDirection = () => {
    props.setSelectedMailIds([]);
  };

  return (
    <div
      style={{ position: "relative", width: "100%", height: "100%" }}
      className="mail_direction text-center"
    >
      <div div className="floating_part">
        {/* {props.selectedMailIds.length > 0 ? (
          <img
            src={require("../../assets/images/icons/selected.svg").default}
            alt="Logo"
          />
        ) : (
          <img
            src={require("../../assets/images/icons/light.svg").default}
            alt="Logo"
          />
        )} */}

        <div>
          <b>
            {props.selectedMailIds.length > 0
              ? props.selectedMailIds.length + " Item's selected"
              : "Select an item to read"}
          </b>
        </div>
        {props.selectedMailIds.length > 0 ? (
          <ul>
            {props.mailFolder.folderName === "Deleted Items" ? (
              <>
                <li>
                  <Link
                    onClick={() =>
                      mailMinimize.bulkRestore(props.selectedMailIds)
                    }
                  >
                    <i class="fa fa-recycle text-success"></i>Restore
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      mailMinimize.bulkPermanentDelete(props.selectedMailIds)
                    }
                  >
                    <i class="fa fa-trash text-danger"></i>Permanently Delete
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link
                  onClick={() => mailMinimize.bulkDelete(props.selectedMailIds)}
                >
                  <i class="fa fa-trash text-danger"></i>Delete
                </Link>
              </li>
            )}

            <li>
              <Link
                onClick={() => mailMinimize.bulkRead(props.selectedMailIds)}
              >
                <i class="fas fa-envelope-open"></i>Mark as read
              </Link>
            </li>

            <li>
              <Link
                onClick={() => mailMinimize.bulkUnread(props.selectedMailIds)}
              >
                <i class="fas fa-envelope"></i>Mark as unread
              </Link>
            </li>
            <li>
              <Link onClick={CancelDirection}>
                <i class="fas fa-times text-danger"></i>Cancel
              </Link>
            </li>
          </ul>
        ) : (
          <div className="text-muted">Nothing is selected</div>
        )}
      </div>
    </div>
  );
}
