import React, { useState, useEffect, useRef } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import ls from "services/ls";
import Select, { components } from "react-select";

export default function MailFolders(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [folders, setFolders] = useState([]);
  const initialFolderSet = useRef(false);
  const [favoriteFolders, setFavoriteFolders] = useState([]);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    folder: null,
  });
  const folderOrder = [
    "Inbox",
    "Drafts",
    "Sent Items",
    "Deleted Items",
    "Archive",
    "Conversation History",
    "Junk Email",
    "Outbox",
    "RSS Feeds",
  ];

  useEffect(() => {
    // Load favorite folders from local storage
    const savedFavorites = ls.get("favoriteFolders") || [];
    setFavoriteFolders(savedFavorites);

    const getMailFolders = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          const accessToken = response.accessToken;

          const foldersResponse = await fetch(
            `${process.env.REACT_APP_MICROSOFT_API_URL}/me/mailFolders?$top=50&$select=displayName,unreadItemCount`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (foldersResponse.ok) {
            const data = await foldersResponse.json();
            let allFolders = data.value;

            const fetchSubfolders = async (parentFolder) => {
              const subfoldersResponse = await fetch(
                `${process.env.REACT_APP_MICROSOFT_API_URL}/me/mailFolders/${parentFolder.id}/childFolders?$top=999&$select=displayName,unreadItemCount`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              if (subfoldersResponse.ok) {
                const subfolderData = await subfoldersResponse.json();
                parentFolder.subfolders = subfolderData.value || [];
              } else {
                parentFolder.subfolders = [];
              }
            };

            for (let folder of allFolders) {
              await fetchSubfolders(folder);
            }

            const sortedFolders = allFolders.sort((a, b) => {
              const indexA = folderOrder.indexOf(a.displayName);
              const indexB = folderOrder.indexOf(b.displayName);
              if (indexA === -1) return 1;
              if (indexB === -1) return -1;
              return indexA - indexB;
            });

            setFolders(sortedFolders);

            if (!initialFolderSet.current) {
              const inboxFolder = sortedFolders.find(
                (folder) => folder.displayName === "Inbox"
              );
              if (inboxFolder) {
                props.setMailFolder({
                  folderName: inboxFolder.displayName,
                  folderId: inboxFolder.id,
                });
                initialFolderSet.current = true;
              }
            }
          } else {
            console.error("Failed to fetch mail folders");
          }
        } catch (error) {
          console.error(
            "Error acquiring token or fetching mail folders",
            error
          );
        }
      }
    };

    if (isAuthenticated) {
      getMailFolders();
    }
  }, [instance, accounts, isAuthenticated]);

  const toggleFavoriteFolder = (folder) => {
    const isFavorite = favoriteFolders.some((fav) => fav.id === folder.id);
    let updatedFavorites;

    if (isFavorite) {
      updatedFavorites = favoriteFolders.filter((fav) => fav.id !== folder.id);
    } else {
      updatedFavorites = [...favoriteFolders, folder];
    }

    setFavoriteFolders(updatedFavorites);
    ls.set("favoriteFolders", updatedFavorites); // Save updated favorites in local storage
  };

  const isFavorite = (folderId) => {
    return favoriteFolders.some((fav) => fav.id === folderId);
  };

  const [expandedFolders, setExpandedFolders] = useState({});

  const toggleFolder = (folderId) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId], // Toggle the expanded state
    }));
  };

  const handleContextMenu = (e, folder) => {
    e.preventDefault(); // Prevent the default right-click behavior
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      folder: folder,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, folder: null });
  };

  useEffect(() => {
    document.addEventListener("click", closeContextMenu);
    return () => document.removeEventListener("click", closeContextMenu);
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="7"
          viewBox="0 0 9 7"
        >
          <path
            id="Polygon_60"
            data-name="Polygon 60"
            d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
            transform="translate(9 7) rotate(180)"
            fill="#707070"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "none",
      border: "none",
      minHeight: "21px",
      height: "21px",
      boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.18)",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "21px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "21px",
    }),
  };

  const [workOrders, setWorkOrders] = useState(
    Array.from({ length: 50 }, (_, index) => {
      const serial = String(index + 1).padStart(2, "0");
      return { value: `WONXF1JM${serial}`, label: `WONXF1JM${serial}` };
    })
  );

  const [poNumbers, setPoNumbers] = useState(
    Array.from({ length: 50 }, (_, index) => {
      const serial = String(index + 1).padStart(2, "0");
      return { value: `PONXF1JM${serial}`, label: `PONXF1JM${serial}` };
    })
  );

  const [selectedBuyer, setSelectedBuyer] = useState("");
  const toggleBuyerArea = (buyeName) => {
    setSelectedBuyer(buyeName);
  };

  return (
    <div
      className={
        props.resizeToggle ? "email-sidebar non_printing_area" : "email-sidebar non_printing_area expanded"
      }
    >
      <button
        onClick={() => {
          const newToggleValue = !props.resizeToggle;
          ls.set("resizeToggle", newToggleValue);
          props.setResizeToggle(newToggleValue);
        }}
        className="resizeToggle"
      >
        {props.resizeToggle ? (
          <i class="fa fa-angle-left"></i>
        ) : (
          <i class="fa fa-angle-right"></i>
        )}
      </button>
      <div className="email-section">
        <div className="folder_name">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
          >
            <path
              id="Polygon_60"
              data-name="Polygon 60"
              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
              transform="translate(9 7) rotate(180)"
              fill="#707070"
            />
          </svg>{" "}
          Favorite
        </div>
        <ul>
          {favoriteFolders.length > 0 ? (
            favoriteFolders.map((folder) => (
              <li style={{ position: "relative" }} key={folder.id}>
                <button
                  className={
                    props.mailFolder.folderId === folder.id ? "active" : ""
                  }
                  onClick={() => {
                    props.setSelectedMailIds([]);
                    props.setMailFolder({
                      folderName: folder.displayName,
                      folderId: folder.id,
                    });
                    props.setMailSearchData([]);
                  }}
                  onContextMenu={(e) => handleContextMenu(e, folder)}
                >
                  {folder.displayName}
                  {folder.unreadItemCount > 0 && (
                    <span>{folder.unreadItemCount}</span>
                  )}
                </button>

                {contextMenu.visible &&
                  contextMenu.folder?.id === folder.id && (
                    <div
                      style={{
                        background: "#fff",
                        border: "1px solid #ccc",
                        position: "absolute",
                        borderRadius: "5px",
                        top: "0px",
                        right: "0px",
                        zIndex: "9999",
                      }}
                      className="context_menu"
                    >
                      <ul style={{ fontSize: "11px", padding: "5px" }}>
                        <li>
                          <button
                            onClick={() =>
                              toggleFavoriteFolder(contextMenu.folder)
                            }
                          >
                            {favoriteFolders.some(
                              (fav) => fav.id === contextMenu.folder.id
                            )
                              ? "Remove from Favorites"
                              : "Add to Favorites"}
                          </button>
                        </li>

                        <li>
                          <button
                            onClick={() => console.log("Hide folder logic")}
                          >
                            Mark All Read
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => console.log("Hide folder logic")}
                          >
                            Mark All Unread
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
              </li>
            ))
          ) : (
            <p>No favorite folders yet.</p>
          )}
        </ul>
      </div>
      <div className="email-section">
        <div className="folder_name">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
          >
            <path
              id="Polygon_60"
              data-name="Polygon 60"
              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
              transform="translate(9 7) rotate(180)"
              fill="#707070"
            />
          </svg>{" "}
          Folders
        </div>

        {isAuthenticated ? (
          <ul>
            {folders.length > 0 ? (
              folders.map((folder) => {
                // Check if the folder has subfolders
                if (!folder.subfolders || folder.subfolders.length === 0) {
                  return (
                    <li style={{ position: "relative" }} key={folder.id}>
                      <button
                        className={
                          props.mailFolder.folderId === folder.id
                            ? "active"
                            : ""
                        }
                        onContextMenu={(e) => handleContextMenu(e, folder)}
                        onClick={() => {
                          props.setSelectedMailIds([]);
                          props.setMailFolder({
                            folderName: folder.displayName,
                            folderId: folder.id,
                          });
                          props.setMailSearchData([]);
                        }}
                      >
                        {folder.displayName}
                        {folder.unreadItemCount > 0 && (
                          <span>{folder.unreadItemCount}</span>
                        )}
                      </button>
                      {contextMenu.visible &&
                        contextMenu.folder?.id === folder.id && (
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #ccc",
                              position: "absolute",
                              borderRadius: "5px",
                              top: "0px",
                              right: "0px",
                              zIndex: "9999",
                            }}
                            className="context_menu"
                          >
                            <ul style={{ fontSize: "11px", padding: "5px" }}>
                              <li>
                                <button
                                  onClick={() =>
                                    toggleFavoriteFolder(contextMenu.folder)
                                  }
                                >
                                  {favoriteFolders.some(
                                    (fav) => fav.id === contextMenu.folder.id
                                  )
                                    ? "Remove from Favorites"
                                    : "Add to Favorites"}
                                </button>
                              </li>

                              <li>
                                <button
                                  onClick={() =>
                                    console.log("Hide folder logic")
                                  }
                                >
                                  Mark All Read
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() =>
                                    console.log("Hide folder logic")
                                  }
                                >
                                  Mark All Unread
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                    </li>
                  );
                }

                return (
                  <li style={{ position: "relative" }} key={folder.id}>
                    <button
                      className={
                        props.mailFolder.folderId === folder.id ? "active" : ""
                      }
                      onClick={() => toggleFolder(folder.id)}
                      onContextMenu={(e) => handleContextMenu(e, folder)}
                    >
                      {folder.displayName}

                      <span>
                        {expandedFolders[folder.id] ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="9"
                            viewBox="0 0 7 9"
                          >
                            <path
                              id="Polygon_176"
                              data-name="Polygon 176"
                              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
                              transform="translate(0 9) rotate(-90)"
                              fill="#707070"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                          >
                            <path
                              id="Polygon_60"
                              data-name="Polygon 60"
                              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
                              transform="translate(9 7) rotate(180)"
                              fill="#707070"
                            />
                          </svg>
                        )}
                      </span>
                    </button>
                    {contextMenu.visible &&
                      contextMenu.folder?.id === folder.id && (
                        <div
                          style={{
                            background: "#fff",
                            border: "1px solid #ccc",
                            position: "absolute",
                            borderRadius: "5px",
                            top: "0px",
                            right: "0px",
                            zIndex: "9999",
                          }}
                          className="context_menu"
                        >
                          <ul style={{ fontSize: "11px", padding: "5px" }}>
                            <li>
                              <button
                                onClick={() =>
                                  toggleFavoriteFolder(contextMenu.folder)
                                }
                              >
                                {favoriteFolders.some(
                                  (fav) => fav.id === contextMenu.folder.id
                                )
                                  ? "Remove from Favorites"
                                  : "Add to Favorites"}
                              </button>
                            </li>

                            <li>
                              <button
                                onClick={() => console.log("Hide folder logic")}
                              >
                                Mark All Read
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => console.log("Hide folder logic")}
                              >
                                Mark All Unread
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                    {expandedFolders[folder.id] && folder.subfolders && (
                      <ul style={{ marginLeft: "15px" }}>
                        {folder.subfolders.map((subfolder) => (
                          <li
                            style={{ position: "relative" }}
                            key={subfolder.id}
                          >
                            <button
                              className={
                                props.mailFolder.folderId === subfolder.id
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                props.setSelectedMailIds([]);
                                props.setMailFolder({
                                  folderName: subfolder.displayName,
                                  folderId: subfolder.id,
                                });
                                props.setMailSearchData([]);
                              }}
                              onContextMenu={(e) =>
                                handleContextMenu(e, subfolder)
                              }
                            >
                              {subfolder.displayName}
                              {subfolder.unreadItemCount > 0 && (
                                <span>{subfolder.unreadItemCount}</span>
                              )}
                            </button>
                            {contextMenu.visible &&
                              contextMenu.folder?.id === subfolder.id && (
                                <div
                                  style={{
                                    background: "#fff",
                                    border: "1px solid #ccc",
                                    position: "absolute",
                                    borderRadius: "5px",
                                    top: "0px",
                                    right: "0px",
                                    zIndex: "9999",
                                  }}
                                  className="context_menu"
                                >
                                  <ul
                                    style={{ fontSize: "11px", padding: "5px" }}
                                  >
                                    <li>
                                      <button
                                        onClick={() =>
                                          toggleFavoriteFolder(
                                            contextMenu.folder
                                          )
                                        }
                                      >
                                        {favoriteFolders.some(
                                          (fav) =>
                                            fav.id === contextMenu.folder.id
                                        )
                                          ? "Remove from Favorites"
                                          : "Add to Favorites"}
                                      </button>
                                    </li>

                                    <li>
                                      <button
                                        onClick={() =>
                                          console.log("Hide folder logic")
                                        }
                                      >
                                        Mark All Read
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() =>
                                          console.log("Hide folder logic")
                                        }
                                      >
                                        Mark All Unread
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })
            ) : (
              <p>No mail folders found.</p>
            )}
          </ul>
        ) : (
          <p>Please sign in to view your mail folders.</p>
        )}
      </div>

      {/* Filtering area  */}

      <div className="email-section">
        <div className="folder_name">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
          >
            <path
              id="Polygon_60"
              data-name="Polygon 60"
              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
              transform="translate(9 7) rotate(180)"
              fill="#707070"
            />
          </svg>{" "} WO-Wise
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
      <div className="email-section">
        <div className="folder_name">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
          >
            <path
              id="Polygon_60"
              data-name="Polygon 60"
              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
              transform="translate(9 7) rotate(180)"
              fill="#707070"
            />
          </svg>{" "} PO-Wise
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
      <div className="email-section">
        <div className="folder_name">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
          >
            <path
              id="Polygon_60"
              data-name="Polygon 60"
              d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
              transform="translate(9 7) rotate(180)"
              fill="#707070"
            />
          </svg>{" "} Sender
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
