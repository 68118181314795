// MailingSingle.js
import React, { useState } from "react";

const MailingSingle = ({
  item,
  index,
  activeIndex,
  handleItemClick,
  handleContextMenu,
  toggleSelectChange,
  markAble,
  selectedMailIds,
  mailMinimize,
  mailFolder,
  onToggleMailChain,
  isSubMail,
}) => {
  const [directiveID, setDirectiveID] = useState("");

  const toggleDirective = (id) => {
    if (directiveID === id) {
      // If the same id is clicked, reset the directiveID
      setDirectiveID("");
    } else {
      // If a different id is clicked, set the new directiveID
      setDirectiveID(id);
    }
  };

  return (
    <li
      style={{
        borderLeft: isSubMail
          ? "4px solid #ef9a3e" // SubMail case
          : item.importance === "high"
          ? "4px solid red" // High importance case
          : "none", // Default case
      }}
      key={`${item.id}-${index}`}
      className={
        index === activeIndex
          ? "list-group-item mailing_single active"
          : "list-group-item mailing_single"
      }
      onClick={() => {
        handleItemClick(index, item.id);
      }}
      onContextMenu={(e) => handleContextMenu(e, item)}
    >
      <div className="mail_text">
        {markAble ? (
          <>
            <input
              onChange={() => toggleSelectChange(item.id)}
              type="checkbox"
              checked={selectedMailIds.includes(item.id)}
            />{" "}
          </>
        ) : (
          !isSubMail &&
          (item.hasMailChain > 1 ? (
            directiveID === item.id ? (
              <svg
                onClick={() => {
                  onToggleMailChain();
                  toggleDirective(item.id);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="7"
                viewBox="0 0 9 7"
              >
                <path
                  id="Polygon_29"
                  data-name="Polygon 29"
                  d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
                  transform="translate(9 7) rotate(180)"
                  fill="#707070"
                />
              </svg>
            ) : (
              <svg
                onClick={() => {
                  onToggleMailChain();
                  toggleDirective(item.id);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 9"
              >
                <path
                  id="Polygon_12"
                  data-name="Polygon 12"
                  d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
                  transform="translate(7) rotate(90)"
                  fill="#707070"
                />
              </svg>
            )
          ) : (
            ""
          ))
        )}
      </div>

      <div
        title={item.subject}
        className={item.isRead === false ? "mail_text unread" : "mail_text"}
      >
        {item.subject ? item.subject : "No Subject"}
      </div>
      <div title={item.sender?.emailAddress?.name} className="mail_text">
        <span className="step_border"></span>
        {item.sender?.emailAddress?.name}
      </div>
      <div title={item.workOrder} className="mail_text">
        <span className="step_border"></span>
        {item.workOrder} | {item.poNumber}
      </div>
      <div
        title={mailMinimize.formatDate(item.receivedDateTime)}
        className="mail_text dateTime"
      >
        <span className="step_border"></span>
        <div className="hide_when_hover">
          {" "}
          {mailMinimize.formatDate(item.receivedDateTime)}
        </div>
        <div className="show_when_hover">
          {/* Display flag and delete icons */}
          {item.flag?.flagStatus === "notFlagged" ? (
            <i
              onClick={() =>
                mailMinimize.toggleFlag(item.id, item.flag.flagStatus)
              }
              className="fa fa-flag me-1"
            ></i>
          ) : item.flag?.flagStatus === "flagged" ? (
            <i
              onClick={() =>
                mailMinimize.toggleFlag(item.id, item.flag.flagStatus)
              }
              className="fa fa-pause me-1"
            ></i>
          ) : (
            <i
              onClick={() =>
                mailMinimize.toggleFlag(item.id, item.flag.flagStatus)
              }
              className="fa fa-check me-1 text-success"
            ></i>
          )}

          {mailFolder.folderName === "Deleted Items" ? (
            <>
              <i
                onClick={() => mailMinimize.handleRestore(item.id)}
                className="fa fa-recycle text-success"
              ></i>
              <i
                onClick={() => mailMinimize.handlePermanentDelete(item.id)}
                className="fa fa-trash text-danger"
              ></i>
            </>
          ) : (
            <i
              onClick={() => mailMinimize.handleDelete(item.id)}
              className="fa fa-trash text-danger"
            ></i>
          )}
        </div>
      </div>
    </li>
  );
};

export default MailingSingle;
