// InboxHeader.js
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const InboxHeader = ({
  markAble,
  toggleSelectAll,
  setFilter,
  setToMeActive,
  toMeFilter,
  toMeActive,
  filter,
  filterToggle,
  filterAble,
  setSortOrder,
  mailFolder,
  emails,
  selectedMailIds,
  toggleMarkAble,
}) => {
  return (
    <div className="inbox-header">
      <div className="inbox_header_left">
        <div className="title">
          {markAble && (
            <input
              onClick={toggleSelectAll}
              type="checkbox"
              checked={selectedMailIds.length === emails.length}
            />
          )}
          {" "}{mailFolder.folderName}
        </div>
        <div className="buttons_group">
          <button
            className={filter === "all" ? "all" : ""}
            onClick={() => {
              setFilter("all");
              setToMeActive(false);
            }}
          >
            All
          </button>
          <button
            className={filter === "unread" ? "unread" : ""}
            onClick={() => {
              setFilter("unread");
              setToMeActive(false);
            }}
          >
            Unread
          </button>
          <button
            className={toMeActive ? "active" : ""}
            onClick={() => {
              toMeFilter();
              setToMeActive(!toMeActive);
            }}
          >
            To me
          </button>
        </div>
      </div>
      <div className="inbox_header_left">
        {emails.length > 0 && (
          <span
            className="toggleSelect"
            style={{ cursor: "pointer" }}
            onClick={toggleMarkAble}
          >
            {markAble ? (
              <img
                style={{ height: "22px", width: "22px" }}
                src={require("../../assets/images/icons/T1-O.png").default}
                alt="Logo"
              />
            ) : (
              <img
                style={{ height: "22px", width: "22px" }}
                src={require("../../assets/images/icons/T1-W.png").default}
                alt="Logo"
              />
            )}
          </span>
        )}
        <Dropdown className="mail_filter_dropdown" onClick={filterToggle}>
          <Dropdown.Toggle
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <img
              style={{ height: "22px", width: "22px" }}
              src={
                filterAble
                  ? require("../../assets/images/icons/Settings-O.png").default
                  : require("../../assets/images/icons/Settings-W.png").default
              }
              alt="Logo"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setSortOrder("date")}>
              Date
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortOrder("from")}>
              From
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortOrder("subject")}>
              Subject
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default InboxHeader;
