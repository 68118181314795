import React, { useState, useEffect } from "react";
import Spinner from "../Spinner";
import { Modal, Button } from "react-bootstrap";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import SingleMailDetails from "./SingleMailDetails";
import MailChain from "./MailChain";

export default function MailDetails(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const getEmailDetails = async () => {
      if (accounts.length > 0 && props.mailID) {
        props.setChainMessages([]);
        try {
          setSpinner(true);
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          // Fetch single email details
          const emailResponse = await fetch(
            `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.mailID}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          setSpinner(false); // Ensure spinner is stopped
          if (emailResponse.ok) {
            const data = await emailResponse.json();

            const [subject, workOrder, poNumber] = data.subject.split("=>");

            // Check if the email is a draft
            if (data.isDraft === true) {
              // Set composing mode to true when it's a draft
              props.setIsComposing(true);
              props.setMailSendType("draftSent");

              // Set the draft email data to props.setSelectedMail
              props.setSelectedMail({
                ...data, // Pass all the draft message data
                subject: subject || "No Subject", // Fallback in case subject is missing
                body: { content: data.body.content || "" }, // Ensure body content is handled
                attachments: data.attachments || [], // Handle draft attachments if any
                toRecipients: data.toRecipients || [],
                ccRecipients: data.ccRecipients || [],
                workOrder: workOrder || "NA",
                poNumber: poNumber || "NA",
              });

              return; // Stop further processing for drafts
            }

            // Separate subject, workOrder, and poNumber

            // Fetch sender's image for single mail
            const senderEmail = data.from.emailAddress.address;
            const senderImage = await getSenderImage(senderEmail, accessToken);

            // Set the single email details including the sender's image and separated fields
            props.setSelectedMail({
              ...data,
              subject: subject || "No Subject", // Fallback in case subject is missing
              workOrder: workOrder || "NA",
              poNumber: poNumber || "NA",
              senderImage, // Add sender image
            });

            // Fetch attachments for the single email
            const attachmentsResponse = await fetch(
              `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.mailID}/attachments`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            let attachments = [];
            if (attachmentsResponse.ok) {
              const attachmentsData = await attachmentsResponse.json();
              attachments = attachmentsData.value;

              // Replace cid references with base64 image data
              let updatedBody = data.body.content;
              attachments
                .filter((attachment) => attachment.isInline)
                .forEach((attachment) => {
                  const cid = attachment.contentId;
                  const base64Image = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
                  updatedBody = updatedBody.replace(`cid:${cid}`, base64Image);
                });

              // Update the selected mail with the modified body and attachments
              props.setSelectedMail((prevDetails) => ({
                ...prevDetails,
                body: { content: updatedBody },
                attachments: attachments,
              }));
            }

            // Fetch mail chain by subject
            if (data.subject) {
              const escapedSubject = data.subject.replace(/'/g, "''");
              const encodedSubject = encodeURIComponent(escapedSubject);
              const rePrefix = encodeURIComponent(`RE: ${escapedSubject}`);
              const fwPrefix = encodeURIComponent(`FW: ${escapedSubject}`);

              const emailChainResponse = await fetch(
                `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages?$filter=startswith(subject,'${encodedSubject}') or startswith(subject,'${rePrefix}') or startswith(subject,'${fwPrefix}')&$top=20`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              if (emailChainResponse.ok) {
                const chainData = await emailChainResponse.json();
                const relatedMessagesWithAttachments = [];

                // Process each message in the mail chain
                for (const message of chainData.value) {
                  if (message.id === props.mailID) {
                    continue; // Skip the currently selected mail
                  }

                  // Fetch sender's image for chain mail
                  const chainSenderEmail = message.from.emailAddress.address;
                  const chainSenderImage = await getSenderImage(
                    chainSenderEmail,
                    accessToken
                  );

                  // Fetch attachments for each message in the mail chain
                  const chainAttachmentsResponse = await fetch(
                    `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${message.id}/attachments`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  let chainAttachments = [];
                  if (chainAttachmentsResponse.ok) {
                    const chainAttachmentsData =
                      await chainAttachmentsResponse.json();
                    chainAttachments = chainAttachmentsData.value;

                    // Replace cid references with base64 image data in chain messages
                    let updatedChainBody = message.body.content;
                    chainAttachments
                      .filter((attachment) => attachment.isInline)
                      .forEach((attachment) => {
                        const cid = attachment.contentId;
                        const base64Image = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
                        updatedChainBody = updatedChainBody.replace(
                          `cid:${cid}`,
                          base64Image
                        );
                      });

                    // Add each chain message with sender image and attachments
                    relatedMessagesWithAttachments.push({
                      ...message,
                      senderImage: chainSenderImage, // Add sender image
                      body: { content: updatedChainBody },
                      attachments: chainAttachments,
                    });
                  }
                }

                // Log the messages before sorting
                console.log(
                  "Messages before sorting:",
                  relatedMessagesWithAttachments
                );

                // Ensure receivedDateTime is a valid date string before sorting
                const sortedMessages = relatedMessagesWithAttachments.sort(
                  (a, b) => {
                    const dateA = new Date(a.receivedDateTime);
                    const dateB = new Date(b.receivedDateTime);

                    // Check if dates are valid
                    if (isNaN(dateA) || isNaN(dateB)) {
                      console.error("Invalid date format:", {
                        a: a.receivedDateTime,
                        b: b.receivedDateTime,
                      });
                      return 0; // Do not change the order for invalid dates
                    }

                    // Sort by date in descending order
                    return dateB - dateA; // Descending order
                  }
                );

                // Log the sorted messages
                // console.log("Sorted messages:", sortedMessages);

                // Set the mail chain messages in state
                props.setChainMessages(sortedMessages);

                // // Set the mail chain messages in state
                // props.setChainMessages(relatedMessagesWithAttachments || []);
              } else {
                console.error(
                  "Failed to fetch email chain:",
                  emailChainResponse.statusText
                );
              }
            }
          } else {
            console.error(
              "Failed to fetch email details:",
              emailResponse.statusText
            );
          }
        } catch (error) {
          console.error(
            "Error acquiring token or fetching email details",
            error
          );
        } finally {
        }
      }
    };

    if (isAuthenticated) {
      console.log("Fetching email details...");
      getEmailDetails();
    }
  }, [instance, accounts, isAuthenticated, props.mailID]);

  const handleToggle = () => {
    props.setExtendDetailsToogle(!props.extendDetailsToggle);
  };

  const getSenderImage = async (emailAddress, accessToken) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MICROSOFT_API_URL}/users/${emailAddress}/photo/$value`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        return response.url; // Returns the photo URL
      } else {
        return null; // No photo available
      }
    } catch (error) {
      console.error("Error fetching sender's image:", error);
      return null;
    }
  };

  return (
    <>
      {spinner && <Spinner />}
      {props.selectedMail.id && (
        <div className="email-content">
          <div className="write_email_subject">
            <div className="workOrder">{props.selectedMail.subject}</div>
            <div className="workOrder">{props.selectedMail.workOrder}</div>
            <div className="workOrder">{props.selectedMail.poNumber}</div>
            <button onClick={handleToggle} className="toggleExtend">
              {props.extendDetailsToggle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.252"
                  height="18.643"
                  viewBox="0 0 18.252 18.643"
                >
                  <path
                    id="Polygon_181"
                    data-name="Polygon 181"
                    d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                    transform="matrix(-0.719, -0.695, 0.695, -0.719, 12.743, 12.438)"
                    fill="#707070"
                  />
                  <path
                    id="Polygon_182"
                    data-name="Polygon 182"
                    d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                    transform="translate(5.408 6.233) rotate(43)"
                    fill="#707070"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.783"
                  height="22.093"
                  viewBox="0 0 20.783 22.093"
                >
                  <path
                    id="Polygon_181"
                    data-name="Polygon 181"
                    d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                    transform="matrix(0.766, 0.643, -0.643, 0.766, 13.359, 0)"
                    fill="#707070"
                  />
                  <path
                    id="Polygon_182"
                    data-name="Polygon 182"
                    d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                    transform="matrix(-0.766, -0.643, 0.643, -0.766, 7.425, 22.093)"
                    fill="#707070"
                  />
                </svg>
              )}
            </button>
          </div>

          <>
            <SingleMailDetails replyble={true} {...props} />
            <MailChain {...props} />
          </>
        </div>
      )}
    </>
  );
}
