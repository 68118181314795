import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import PropTypes from "prop-types";
import Toolbar from "./Toolbar";
import SingleMailDetails from "./SingleMailDetails";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

//gjfkgj f
import CustomEditor from "./CustomEditor";
import QuailEditor from "./QuailEditor";

export default function SentMail(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [workOrder, setWorkOrder] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [contacts, setContacts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getContacts = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          let allUsers = []; // Array to hold all users
          let nextLink = `${process.env.REACT_APP_MICROSOFT_API_URL}/users?$select=displayName,mail`; // Initial request URL

          // Loop to fetch all pages of users
          do {
            const contactResponse = await fetch(nextLink, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            });

            if (contactResponse.ok) {
              const data = await contactResponse.json();
              allUsers = allUsers.concat(data.value); // Add current page of users to the list
              nextLink = data["@odata.nextLink"]; // Get the next link for pagination
            } else {
              console.error(
                "Failed to fetch contacts:",
                await contactResponse.json()
              );
              nextLink = null; // Stop fetching if there's an error
            }
          } while (nextLink); // Continue while there's a next link

          setContacts(allUsers); // Set all users to the state
        } catch (error) {
          console.error("Error acquiring token or fetching contacts", error);
        }
      }
    };

    if (isAuthenticated) {
      getContacts();
    }
  }, [instance, accounts, isAuthenticated]);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleEmailChange = (emails, setEmails) => {
    setEmails(emails.filter(isValidEmail));
  };

  const handleToEmailChange = (event, value) => {
    handleEmailChange(value, setToEmails);
  };

  const handleCcEmailChange = (event, value) => {
    handleEmailChange(value, setCcEmails);
  };

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files to existing files
  };

  const handleFileDelete = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    const { selectedMail, mailSendType } = props;

    if (selectedMail) {
      const {
        subject,
        sender,
        toRecipients = [],
        ccRecipients = [],
        body,
        poNumber,
        workOrder,
      } = selectedMail;
      switch (mailSendType) {
        case "Reply":
          setSubject(`RE: ${subject}`);
          setToEmails([sender.emailAddress.address]);
          setCcEmails([]);
          setWorkOrder(workOrder);
          setPoNumber(poNumber);
          break;

        case "ReplyAll":
          setSubject(`RE: ${subject}`);
          setToEmails([
            sender.emailAddress.address,
            ...toRecipients.map((rec) => rec.emailAddress.address),
          ]);
          setCcEmails(ccRecipients.map((rec) => rec.emailAddress.address));
          setWorkOrder(workOrder);
          setPoNumber(poNumber);
          break;

        case "draftSent":
          setSubject(subject || "");
          setToEmails(toRecipients.map((rec) => rec.emailAddress.address));
          setCcEmails(ccRecipients.map((rec) => rec.emailAddress.address));
          setDescription(body?.content || "");
          setWorkOrder(workOrder);
          setPoNumber(poNumber);
          break;

        case "Forward":
          setSubject(`FW: ${subject}`);
          setToEmails([]);
          setCcEmails([]);
          setWorkOrder(workOrder);
          setPoNumber(poNumber);
          break;

        default:
          // For new email, reset the fields
          setSubject("");
          setToEmails([]);
          setCcEmails([]);
          setDescription(""); // Reset description for new email
          break;
      }
    }
  }, [props.mailSendType, props.selectedMail]);



  const handleSendEmail = async () => {
    if (props.mailSendType === "Send") {
      if (!toEmails.length || !subject || !description) {
        setErrorMessage("To, Subject, and Body are required.");
        return;
      }
    }

    const fullSubject = `${subject}=>${workOrder}=>${poNumber}`;

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const accessToken = response.accessToken;

      const attachments = await Promise.all(
        selectedFiles.map(async (file) => {
          const base64File = await fileToBase64(file);
          return {
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: file.name,
            contentType: file.type,
            contentBytes: base64File.replace(/^data:.+;base64,/, ""), // Strip base64 prefix
          };
        })
      );

      const message = {
        subject: fullSubject,
        body: {
          contentType: "HTML",
          content: description,
        },
        toRecipients: toEmails.map((email) => ({
          emailAddress: { address: email },
        })),
        ccRecipients: ccEmails.map((email) => ({
          emailAddress: { address: email },
        })),
        attachments,
        importance: importance,
      };

      // Check the mailSendType to decide whether it's a new email, reply, forward, etc.
      let url = "";
      let method = "POST";
      const selectedMailId = props.selectedMail?.id;

      if (props.mailSendType === "Reply") {
        url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${selectedMailId}/reply`;
      } else if (props.mailSendType === "ReplyAll") {
        url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${selectedMailId}/replyAll`;
      } else if (props.mailSendType === "Forward") {
        url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${selectedMailId}/forward`;
      } else if (props.mailSendType === "draftSent") {
        url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${selectedMailId}/send`;
      } else {
        url = `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages`;
      }

      // Send the request to Microsoft Graph API based on the mailSendType
      let apiResponse;
      if (props.mailSendType === "Reply" || props.mailSendType === "ReplyAll") {
        apiResponse = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comment: description,
            attachments,
            importance:importance, // Include the attachments here
          }),
        });
      } else if (props.mailSendType === "Forward") {
        apiResponse = await fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comment: description,
            toRecipients: toEmails.map((email) => ({
              emailAddress: { address: email },
            })),
            attachments,
            importance:importance,
          }),
        });
      } else if (props.mailSendType === "draftSent") {
        apiResponse = await fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comment: description,
            attachments,
            importance:importance,
          }),
        });
      } else {
        // Sending a new email
        const createMessageResponse = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(message),
        });

        if (!createMessageResponse.ok) {
          throw new Error("Failed to create draft message");
        }
        const createdMessage = await createMessageResponse.json();

        apiResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${createdMessage.id}/send`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }

      if (apiResponse.ok) {
        setSuccessMessage("Email sent successfully!");
        setToEmails([]);
        setCcEmails([]);
        setSubject("");
        setDescription("");
        setSelectedFiles([]);
        props.setIsComposing(false);
        props.setMailSendType("Send");
      } else {
        setErrorMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while sending the email.", error);
      setErrorMessage("An error occurred while sending the email.");
    } finally {
      setLoading(false);
    }
  };

  //Handle Discard

  const handleDiscard = () => {
    if (
      subject ||
      description ||
      toEmails.length > 0 ||
      selectedFiles.length > 0
    ) {
      // Show SweetAlert when trying to discard
      swal({
        title: "Discard Changes?",
        text: "Do you want to save this email to drafts before discarding?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: {
            text: "Save to Draft",
            value: true,
          },
        },
      }).then((saveToDraft) => {
        if (saveToDraft) {
          handleSaveDraft(); // Call the save draft function
        } else {
          props.setIsComposing(false); // Close the compose window without saving
        }
      });
    } else {
      // If nothing to save, simply close the compose window
      props.setIsComposing(false);
    }
  };

  // Function to save the email as a draft
  const handleSaveDraft = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const accessToken = response.accessToken;

      const attachments = await Promise.all(
        selectedFiles.map(async (file) => {
          const base64File = await fileToBase64(file);
          return {
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: file.name,
            contentType: file.type,
            contentBytes: base64File.replace(/^data:.+;base64,/, ""), // Strip base64 prefix
          };
        })
      );

      const fullSubject = `${subject}=>${workOrder}=>${poNumber}`;

      const draftMessage = {
        subject: fullSubject,
        body: {
          contentType: "HTML",
          content: description,
        },
        toRecipients: toEmails.map((email) => ({
          emailAddress: { address: email },
        })),
        ccRecipients: ccEmails.map((email) => ({
          emailAddress: { address: email },
        })),
        attachments,
        importance:importance,
      };

      let createDraftResponse; // Declare once before the conditional block

      if (props.mailSendType === "draftSent") {
        createDraftResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.selectedMail?.id}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(draftMessage),
          }
        );
      } else if (props.mailSendType === "Reply") {
        createDraftResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.selectedMail?.id}/createReply`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              comment: description,
              attachments,
              importance:importance,
            }),
          }
        );
      } else if (props.mailSendType === "ReplyAll") {
        createDraftResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.selectedMail?.id}/createReplyAll`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              comment: description,
              attachments,
              importance:importance,
            }),
          }
        );
      } else if (props.mailSendType === "Forward") {
        createDraftResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages/${props.selectedMail?.id}/createForward`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              comment: description,
              toRecipients: toEmails.map((email) => ({
                emailAddress: { address: email },
              })),
              importance:importance,
            }),
          }
        );
      } else {
        createDraftResponse = await fetch(
          `${process.env.REACT_APP_MICROSOFT_API_URL}/me/messages`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(draftMessage),
          }
        );
      }

      if (createDraftResponse.ok) {
        const draft = await createDraftResponse.json();
        setSuccessMessage("Email saved to drafts successfully!");
        props.setMailSendType("Send");
        props.setIsComposing(false); // Close the compose window
        props.setMailFolder(props.mailFolder);
        props.setSelectedMail(draft);
        history.pushState("/mailbox");
      } else {
        setErrorMessage("Failed to save the draft. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while saving the draft.", error);
      setErrorMessage("An error occurred while saving the draft.");
    }
  };

  const editorRef = useRef(null);

  const handleContentChange = (value) => {
    setDescription(value);
  };

  const handleUndo = () => {
    if (editorRef.current) {
      editorRef.current.getEditor().history.undo();
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      editorRef.current.getEditor().history.redo();
    }
  };

  const [importance, setImportance] = useState("normal");
  const toggleImportance = () => {
    if (importance === "normal") {
      setImportance("high");
    } else {
      setImportance("normal");
    }
  };


  const handleToggle = () => {
    props.setExtendDetailsToogle(!props.extendDetailsToggle);
  };

  return (
    <div className="compose-email">
      <div className="write_email_subject">
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="workOrder"
        />
        <Autocomplete
          className="workOrder"
          freeSolo
          options={["WO001", "WO002", "WO003", "WO004", "WO005", "WO006", "NA"]}
          value={workOrder}
          onChange={(e, newValue) => setWorkOrder(newValue)}
          renderInput={(params) => <TextField {...params} placeholder="WO" />}
        />

        <Autocomplete
          className="workOrder"
          freeSolo
          options={["PO001", "PO002", "PO003", "PO004", "PO005", "PO006", "NA"]}
          value={poNumber}
          onChange={(e, newValue) => setPoNumber(newValue)}
          renderInput={(params) => (
            <TextField className="workOrder" {...params} placeholder="PO" />
          )}
        />

        <button onClick={handleToggle} className="toggleExtend">
          {props.extendDetailsToggle ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.252"
              height="18.643"
              viewBox="0 0 18.252 18.643"
            >
              <path
                id="Polygon_181"
                data-name="Polygon 181"
                d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                transform="matrix(-0.719, -0.695, 0.695, -0.719, 12.743, 12.438)"
                fill="#707070"
              />
              <path
                id="Polygon_182"
                data-name="Polygon 182"
                d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                transform="translate(5.408 6.233) rotate(43)"
                fill="#707070"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.783"
              height="22.093"
              viewBox="0 0 20.783 22.093"
            >
              <path
                id="Polygon_181"
                data-name="Polygon 181"
                d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                transform="matrix(0.766, 0.643, -0.643, 0.766, 13.359, 0)"
                fill="#707070"
              />
              <path
                id="Polygon_182"
                data-name="Polygon 182"
                d="M3.993,1.4A1,1,0,0,1,5.7,1.4L8.763,6.409A1,1,0,0,1,7.91,7.93H1.783A1,1,0,0,1,.93,6.409Z"
                transform="matrix(-0.766, -0.643, 0.643, -0.766, 7.425, 22.093)"
                fill="#707070"
              />
            </svg>
          )}
        </button>
      </div>
      <Toolbar
        onUndo={handleUndo}
        onRedo={handleRedo}
        toggleImportance={toggleImportance}
        handleFileSelect={handleFileSelect}
        importance={importance}
      />

      <div className="mail_body_area_composing">
        <div className="to_send_area">
          <div className="sentbtn">
            <button onClick={handleSendEmail} disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
          <div className="to_cc">
            <div className="to_field">
              <Autocomplete
                multiple
                freeSolo
                options={contacts
                  .map((contact) => contact.mail || contact.displayName)
                  .filter((option) => !toEmails.includes(option))}
                value={toEmails}
                onChange={handleToEmailChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="TO: "
                  />
                )}
              />
            </div>

            <div className="cc_field">
              <Autocomplete
                multiple
                freeSolo
                options={contacts
                  .map((contact) => contact.mail || contact.displayName)
                  .filter((option) => !ccEmails.includes(option))}
                value={ccEmails}
                onChange={handleCcEmailChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="CC" />
                )}
              />
            </div>
          </div>
          <div className="discard">
            <button onClick={handleDiscard}>Discard</button>
          </div>
        </div>
        <br />
        <div className="attachment-container">
          {selectedFiles.map((file, index) => (
            <span
              key={index}
              style={{
                padding: "5px",
                background: "#e9ecef",
                border: "1px solid #ced4da",
                borderRadius: "4px",
                fontSize: "11px",
                margin: "5px",
                display: "inline-block",
                position: "relative",
              }}
            >
              {file.name.endsWith("png") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-image"
                ></i>
              ) : file.name.endsWith("jpg") || file.name.endsWith("jpeg") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file-image"
                ></i>
              ) : file.name.endsWith("pdf") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file-pdf"
                ></i>
              ) : file.name.endsWith("doc") || file.name.endsWith("docx") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file-word"
                ></i>
              ) : file.name.endsWith("xls") || file.name.endsWith("xlsx") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file-excel"
                ></i>
              ) : file.name.endsWith("ppt") || file.name.endsWith("pptx") ? (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file-powerpoint"
                ></i>
              ) : (
                <i
                  style={{
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                  className="fa fa-file"
                ></i>
              )}

              {file.name}
              <i
                className="fas fa-times"
                style={{
                  color: "red",
                  fontSize: "15px",
                  position: "absolute",
                  top: "-8px",
                  right: "-8px",
                  cursor: "pointer",
                }}
                onClick={() => handleFileDelete(index)}
              ></i>
            </span>
          ))}
        </div>
        <br />

        <CustomEditor
          ref={editorRef}
          content={description}
          onContentChange={handleContentChange}
          className="editor"
        />

        {errorMessage && (
          <div className="error-message text-danger">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="success-message text-success">{successMessage}</div>
        )}
      </div>

      {props.mailSendType !== "Send" && (
        <SingleMailDetails replyble={false} {...props} />
      )}
    </div>
  );
}

SentMail.propTypes = {
  setIsComposing: PropTypes.func.isRequired,
};
